<template>
  <default-layout>
    <div class="back-link">
      <button v-button:to-list @click="returnToList"/>
    </div>
    <section v-if="examTerm">
      <h2 class="section-title"><span class="icon">edit_square_outline</span>試験詳細</h2>
      <div class="table-responsive">
        <table class="table table-bordered" v-cols="[16, 17, 16, 17, 16, 18]">
          <tbody>
          <tr>
            <th>ID</th>
            <td class="text-center">{{examTerm.examTermId}}</td>
            <th>名前</th>
            <td class="text-left">{{examTerm.name}}</td>
            <th>試験問題コード</th>
            <td class="text-left">{{examTerm.examFormGroupCode}}</td>
          </tr>
          <tr>
            <th>実施期間</th>
            <td colspan="5">{{examTerm.startTime | datetime}} 〜 {{examTerm.endTime | datetime}}</td>
          </tr>
          <tr>
            <th>受験者修正期限</th>
            <td colspan="5">{{examTerm.correctionDeadline | datetime}}</td>
          </tr>
          <tr v-if="isGakken">
            <th>採点日時</th>
            <td colspan="2" class="text-center">
              <div v-if="examTerm.marked">{{examTerm.markedTime | datetime}}</div>
              <div v-else>-</div>
            </td>
            <th>結果公開</th>
            <td colspan="2" class="text-center">{{examTerm.showResults ? "公開済" : "-"}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center">
        <button v-button:edit @click="editExamTerm"/>
        <button v-button:delete @click="deleteExamTerm" v-if="deletable"/>
        <button v-button:primary @click="$move('sendmail', { examTermId })" v-if="!examTerm.ended">アカウント登録メールを送信する</button>
      </div>
    </section>

    <section v-if="examTerm" class="form-inline">
      <h2 class="section-title"><span class="icon">corporate_fare</span>会場別期間設定</h2>
      <div class="table-responsive">
        <table class="table table-bordered" v-cols="[5, 25, 60, 10]">
          <tbody>
          <tr>
            <th>会場コード</th>
            <th>会場名</th>
            <th>実施期間</th>
            <th></th>
          </tr>
          <tr v-for="examSite in examSites" :key="examSite.examSiteId">
            <td class="text-center">{{examSite.siteCode}}</td>
            <td class="text-left">{{examSite.siteName}}</td>
            <td v-show="examSite.editRow">
              <div>
                <span>開始日時：</span>
                <span class="form-inner">
                  <input-date v-model="examSite.startEditDate" :error="$error('startDate')"/>
                </span>
                <span class="form-inner">
                  <input-time v-model="examSite.startEditTime" :error="$error('startTime')"/>
                </span>
              </div>
              <div>
                <span>終了日時：</span>
                <span class="form-inner">
                  <input-date v-model="examSite.endEditDate" :error="$error('endDate')"/>
                </span>
                <span class="form-inner">
                  <input-time v-model="examSite.endEditTime" :error="$error('endTime')"/>
                </span>
              </div>
            </td>
            <td v-show="examSite.editRow">
              <div class="text-center mb-1"><button v-button:primary @click="updateExamSite(examSite)" >登録する</button></div>
              <div class="text-center"><button v-button:cancel @click="cancelExamSite()" /></div>
            </td>
            <td v-show="!examSite.editRow">
              <div v-if="examSite.startTime || examSite.endTime" class="text-center">{{examSite.startTime | datetime}} 〜 {{examSite.endTime | datetime}}</div>
              <div v-else class="text-center">編集中</div>
            </td>
            <td v-show="!examSite.editRow"><div class="text-center">
              <button v-button:edit @click="editExamSite(examSite)" :disabled="editMode"/>
            </div>
            </td>
          </tr>
          <tr>
            <td><input-text v-model="siteCode" :error="$error('siteCode')"/></td>
            <td></td>
            <td></td>
            <td><div class="text-center">
              <button v-button:add @click="getExamSite" :disabled="editMode"></button>
            </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </section>

    <section v-if="examTerm">
      <h2 class="section-title"><span class="icon">output</span>ファイル出力</h2>
      <div class="text-center">
        <button v-button:download v-button:primary @click="downloadExamAnswers">解答データ</button>
        <button v-button:download v-button:primary @click="downloadExamineeCorrections">受検者情報修正データ</button>
        <button v-button:download v-button:primary @click="downloadExaminees">受検状況データ</button>
      </div>
      <div class="mt-2 text-center">
        <button v-button:download @click="downloadScoreReports" v-if="certificateFile">スコアレポートダウンロード</button>
        <button v-button:download @click="downloadCertificates" v-if="reportFile">合格証ダウンロード</button>
      </div>
    </section>

    <modal ref="examTermModal" title="編集">
      <exam-term-modal :exam-term-id="examTerm ? examTerm.examTermId : null" @close="closeExamTermModal"/>
    </modal>
  </default-layout>
</template>

<script>
import {Service} from './_resource';
import ExamTermModal from "./ExamTermModal";
import InputText from "@/calico-vue/form/components/InputText.vue";
import InputDate from "@/calico-vue/form/components/InputDate.vue";
import InputTime from "@/calico-vue/form/components/InputTime.vue";
import DateUtil from "@/calico-vue/misc/DateUtil";
import {announce, MediaService} from "@/calico-vue/service";

export default {
  components: {InputTime, InputDate, InputText, ExamTermModal},
  data() {
    return {
      isGakken: process.env.VUE_APP_MODE_GAKKEN === 'true',
      examTerm: null,
      deletable: false,
      reportFile: false,
      certificateFile: false,
      error: null,
      siteCode: null,
      examSites: null,
      editMode: false
    }
  },
  computed: {
    examTermId: vm => vm?.$route.query.examTermId
  },
  created() {
    this.refreshExamTerm();
  },
  methods: {
    returnToList() {
      this.$move('index', null, true);
    },
    refreshExamTerm() {
      return Service.fetchExamTerm(this.examTermId).then(data => {
        this.examTerm = data.examTerm;
        this.deletable = data.deletable;
        this.reportFile = data.reportFile;
        this.certificateFile = data.certificateFile;
        this.examSites = data.examSites.map(ex => {
          return {
            siteId: ex.siteId,
            siteCode: ex.siteCode,
            siteName: ex.siteName,
            startTime: ex.startTime,
            endTime: ex.endTime,
            startEditDate: DateUtil.format(ex.startTime, 'YYYY-MM-DD'),
            startEditTime: DateUtil.format(ex.startTime, 'HH:mm'),
            endEditDate: DateUtil.format(ex.endTime, 'YYYY-MM-DD'),
            endEditTime: DateUtil.format(ex.endTime, 'HH:mm'),
            editMode: false,
          };
        });
      }).catch(error => this.error = error);
    },
    editExamTerm() {
      this.$refs.examTermModal.open();
    },
    deleteExamTerm() {
      if (!confirm('削除してもよろしいですか？')) return;
      Service.deleteExamTerm(this.examTerm.examTermId).then(() => {
        this.returnToList();
      }).catch(error => this.error = error);
    },
    closeExamTermModal(refresh) {
      if (refresh) {
        this.refreshExamTerm().then(() => this.$refs.examTermModal.close());
      } else {
        this.$refs.examTermModal.close();
      }
    },
    getExamSite() {
      Service.getExamSite(this.siteCode, this.examTermId).then(data => {
        this.examSites.push({siteId: data.siteId, siteCode: data.siteCode, siteName: data.siteName, editRow:true});
        this.editMode = true;
        this.siteCode = null;
      }).catch(error => this.error = error);
    },
    editExamSite(examSite) {
      this.editMode = true;
      examSite.editRow = true;
    },
    updateExamSite(examSite) {
      Service.updateExamSite({examTermId:this.examTermId, siteId: examSite.siteId, startDate: examSite.startEditDate, startTime: examSite.startEditTime, endDate: examSite.endEditDate, endTime: examSite.endEditTime}).then(() => {
        examSite.startTime = examSite.startEditDate + ' ' + examSite.startEditTime;
        examSite.endTime = examSite.endEditDate + ' ' + examSite.endEditTime;
        announce.saved();
        this.editMode = false;
        for(let es of this.examSites) {
          es.editRow = false;
        }
        this.deletable = false;
      }).catch(error => this.error = error);
    },
    cancelExamSite() {
      this.editMode = false;
      for(let es of this.examSites) {
        es.editRow = false;
      }
    },
    downloadExamAnswers() {
      Service.downloadExamAnswers(this.examTermId).then(data => {
        MediaService.download(data.csv);
      })
    },
    downloadExamineeCorrections() {
      Service.downloadExamineeCorrections(this.examTermId).then(data => {
        MediaService.download(data.csv);
      })
    },
    downloadExaminees() {
      Service.downloadExaminees(this.examTermId).then(data => {
        MediaService.download(data.csv);
      })
    },
    downloadScoreReports() {
      window.open("/download/endpoint/exam/download_score_reports?examTermId=" + this.examTermId, "_blank");
    },
    downloadCertificates() {
      window.open("/download/endpoint/exam/download_certificates?examTermId=" + this.examTermId, "_blank");
    }
  }
};
</script>
